<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" class="w-4">
    <title>Analytics</title>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1">
        <path
          class="fill-current"
          d="M8.05,9.93H6.14a.3.3,0,0,0-.23.09.32.32,0,0,
     0-.09.23V15.1a.31.31,0,0,0,.32.32H8.05a.29.29,0,
     0,0,.22-.09.3.3,0,0,0,.09-.23V10.25A.32.32,0,0,
     0,8.27,10,.29.29,0,0,0,8.05,9.93Z"
        />
        <path
          class="fill-current"
          d="M4.23,11.6H2.32a.31.31,0,0,0-.32.32V15.1a.3.3,0,0,0,
     .09.23.32.32,0,0,0,.23.09H4.23a.32.32,0,0,0,.23-.09.3.3,
     0,0,0,.09-.23V11.92a.31.31,0,0,0-.32-.32Z"
        />
        <path
          class="fill-current"
          d="M11.86,6.11H10a.29.29,0,0,0-.22.09.32.32,0,0,
     0-.09.23V15.1a.3.3,0,0,0,.09.23.29.29,0,0,0,.22.09h1.91a.31.31,
     0,0,0,.32-.32V6.43a.32.32,0,0,0-.09-.23A.3.3,0,0,0,11.86,6.11Z"
        />
        <path
          class="fill-current"
          d="M15.91,2.67a.32.32,0,0,0-.23-.09H13.77a.32.32,0,0,0-.23.09.3.3,
     0,0,0-.09.23V15.1a.3.3,0,0,0,.09.23.32.32,0,0,0,.23.09h1.91a.32.32,
     0,0,0,.23-.09A.3.3,0,0,0,16,15.1V2.9A.3.3,0,0,0,15.91,2.67Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AnalyticsSvg',
};
</script>
